<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    :title="label"
    :button2-text="button2text"
    @button2="save"
    :main-btn-disabled="selected.length === 0"
    :max-width="800"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text v-if="selectables.length">
          <v-slide-group v-model="selected" multiple class="d-flex">
            <v-slide-item
              v-for="(item, i) in selectables"
              :key="i"
              :value="item.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                height="180"
                width="180"
                @click="toggle"
              >
                <v-row
                  class="flex-column fill-height"
                  align="center"
                  justify="center"
                >
                  <v-avatar size="100">
                    <v-img
                      :lazy-src="settings.loader"
                      contain
                      :src="item.image_url"
                    ></v-img>
                  </v-avatar>
                  <strong
                    :class="active ? 'white--text' : 'primary--text'"
                    class="mt-3"
                  >
                    {{ item.fullname | ucwords }}
                  </strong>
                </v-row>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card-text>
        <Empty
          min-height="300"
          v-else
          :headline="`No more available user (${role})`"
        ></Empty>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _ from 'lodash'
export default {
  name: 'AddTeamMemberDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false },
    label: { type: String, default: 'Select member' },
    list: { type: Array },
    role: { type: String, default: 'Members', required: true }
  },
  computed: {
    button2text() {
      return this.selected.length
        ? `Add Selected (${this.selected.length})`
        : `Add Selected`
    }
  },
  data: () => ({
    dialog: false,
    selected: [],
    selectables: []
  }),
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val) {
        this.selected = []
      }
    },
    list: {
      handler: function (val) {
        if (val) {
          this.selectables = _.cloneDeep(val)
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    save() {
      if (this.selected.length) {
        this.$emit('save', {
          role: this.role,
          users: this.selected
        })
      }
    }
  }
}
</script>
<style></style>
