import { fetch_services } from '@/services/fetch/fetch_services'
import { mapGetters } from 'vuex'
import _ from 'lodash'
//components
import DeleteDialog from '@/common/DeleteDialog.vue'
import AddTeamMemberDialog from './components/AddTeamMemberDialog.vue'
import UserCard from '@/common/UserCard.vue'
export default {
    name: 'MembersTab',
    mixins: [fetch_services],
    components: {
        DeleteDialog,
        UserCard,
        AddTeamMemberDialog
    },
    props: {
        id: { type: [Number, String], require: true }
    },

    data: () => ({
        team_action: 0,
        headers: [
            { text: 'Member', value: 'fullname' },
            { text: 'Email', value: 'email' },
            { text: 'Telephone', sortable: false },
            { text: 'Position', value: 'job_title' },
            { text: 'Tasks', value: 'tasks_count' },
            {
                text: 'Action',
                value: 'action',
                align: 'center',
                width: 140,
                sortable: false
            }
        ],
        sortList: [
            { title: 'Sort by member' },
            { title: 'Sort by email' },
            { title: 'Sort by telephone' },
            { title: 'Sort by position' },
            { title: 'Sort by tasks' }
        ],
        add_manager_dialog: false,
        add_member_dialog: false,
        activeRole: null,
        project_users: []
    }),

    computed: {
        ...mapGetters([
            'user',
            'users',
            'view_type',
            'company_admins_managers',
            'company_non_client_members'
        ]),
        ...mapGetters('project', ['project']),
        client() {
            return _.cloneDeep(this.items).find((i) => i.pivot.role === 'Client')
        },
        managers() {
            return _.cloneDeep(this.items).filter((i) => i.pivot.role === 'Manager')
        },
        members() {
            return _.cloneDeep(this.items).filter((i) => i.pivot.role === 'Members')
        },
        permissions() {
            return this.$_permissions.get('projects') || null
        },
        paths() {
            return [{
                    text: 'Dashboard',
                    disabled: false,
                    route: { name: 'default-content' }
                },
                {
                    text: this.type,
                    disabled: false,
                    route: { path: `/dashboard/${this.type}/preview/${this.id}` }
                },
                { text: 'Members', disabled: true, route: null }
            ]
        },
        can_add() {
            if (this.user.is_admin || this.user.is_manager) {
                return true
            }
            return this.permissions && this.permissions.create ? true : false
        },
        can_remove() {
            if (this.user.is_admin || this.user.is_manager) {
                return true
            }
            return this.permissions && this.permissions.delete ? true : false
        },
        current_managers() {
            return this.managers.map((i) => i.id)
        },
        current_members() {
            return this.members.map((i) => i.id)
        },
        users_that_can_be_manager() {
            return _.cloneDeep(this.company_admins_managers).filter((i) => {
                return (!this.current_members.includes(i.id) &&
                    !this.current_managers.includes(i.id)
                )
            })
        },
        users_that_can_be_member() {
            return _.cloneDeep(this.company_non_client_members).filter((i) => {
                return (!this.current_members.includes(i.id) &&
                    !this.current_managers.includes(i.id)
                )
            })
        }
    },

    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.fetchProjectUsers()
        if (!this.users.length) {
            this.$store.dispatch('fetchUsers')
        }
    },

    methods: {
        fetchProjectUsers() {
            this.loadItems(
                `api/projects/${this.id}/member?all=true`,
                false,
                null,
                null
            )
        },
        navigate_to_view_profile(user) {
            this.$router.push({
                name: 'profile',
                params: { username: user.username }
            })
        },
        handleNewManagers(payload) {
            this.addItems(
                `api/projects/${this.id}/members`,
                payload,
                'New managers added',
                (data) => {
                    this.$store.commit('project/add_managers', data)
                    this.add_manager_dialog = false
                },
                null
            )
        },
        handleNewMembers(payload) {
            this.addItems(
                `api/projects/${this.id}/members`,
                payload,
                'New members added',
                (data) => {
                    this.$store.commit('project/add_members', data)
                    this.add_member_dialog = false
                },
                null
            )
        },
        prepareDeleteMember(user, role) {
            this.activeRole = role
            this.action_confirm_dialog(true, user)
        },
        handleRemoveMember() {
            if (this.activeItem && this.activeRole) {
                let user_id = this.activeItem.id
                this.deleteItem(
                    `api/projects/${this.id}/${user_id}/${this.activeRole}`,
                    `Project member removed`,
                    () => {
                        this.action_confirm_dialog(false, null)
                        this.$store.commit('project/remove_managers', user_id)
                        this.$store.commit('project/remove_members', user_id)
                        this.activeRole = null
                    },
                    null
                )
            }
        }
    }
}